// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-feest-js": () => import("../src/pages/feest.js" /* webpackChunkName: "component---src-pages-feest-js" */),
  "component---src-pages-gala-js": () => import("../src/pages/gala.js" /* webpackChunkName: "component---src-pages-gala-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pubquiz-js": () => import("../src/pages/pubquiz.js" /* webpackChunkName: "component---src-pages-pubquiz-js" */),
  "component---src-pages-reis-js": () => import("../src/pages/reis.js" /* webpackChunkName: "component---src-pages-reis-js" */),
  "component---src-pages-vazo-js": () => import("../src/pages/vazo.js" /* webpackChunkName: "component---src-pages-vazo-js" */)
}

